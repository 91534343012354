import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../utils/buttons/Button";

interface LoginFormProps {
  showSpinner: (val: boolean) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ showSpinner }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError("");
    showSpinner(true);

    try {
      const response = await axios.post(
        "https://backend.inferixai.link/api/login",
        {
          username: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { token } = response.data;

      // Store the token in session storage
      sessionStorage.setItem("token", token);

      // Store the userId in session storage
      sessionStorage.setItem("username", username);

      // Redirect to lobby or the requested path
      navigate("/lobby");
    } catch (err: any) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.description) {
        setError(err.response.data.description);
      } else {
        setError("Failed to login");
      }
    } finally {
      showSpinner(false);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col mb-2">
          <input
            className="mt-1"
            type="text"
            id="login-username"
            value={username}
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col mt-4 mb-2">
          <input
            className="mt-1"
            type="password"
            id="login-password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex w-full justify-center mt-[40px]">
          <Button classes="w-4/12" type="submit" text={"Login"}></Button>
        </div>
      </form>
      <div className="flex w-full p-2 mt-3 text-red-600 text-sm font-medium justify-center">
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default LoginForm;
