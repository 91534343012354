import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import favActiveIcon from "../../assets/icons/fav_active_icon.png";
import favIcon from "../../assets/icons/fav_icon.png";
import { favTablesLoaded } from "../../store/favTablesSlice";
import { Table } from "../../store/tablesModel";
import { TableDataMap } from "./constants/tablesData";

interface GameTabProps {
  table: Table;
  favTables: string[];
  onGameClick: (gameId: string) => void;
}

const GameTab: React.FC<GameTabProps> = ({ table, favTables, onGameClick }) => {
  const deviceType = localStorage.getItem("deviceType");
  const [divSize, setDivSize] = useState({ width: 0, height: 0 });
  const dispatch = useDispatch();

  useEffect(() => {
    const folder = table.slug;

    const img = new Image();
    img.src = `/assets/tables/${folder}/img_00.png`;

    img.onload = () => {
      setDivSize({ width: img.width, height: img.height });
    };

    // Create keyframes dynamically for this table
    const styleSheet = document.styleSheets[0];
    const keyframes = `
      @keyframes slide_${folder} {
        ${Array.from({ length: 50 })
          .map((_, i) => {
            const percentage = (i / 49) * 100; // Calculate percentage for each frame
            return `${percentage}% { background-image: url('/assets/tables/${folder}/img_${String(
              i
            ).padStart(2, "0")}.png'); }`;
          })
          .join("\n")}
      }
    `;

    // Insert keyframes rule
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, [table]);

  const handleFavClick = async () => {
    try {
      const response = await axios.post(
        "https://backend.inferixai.link/api/favorites",
        {
          tableId: table.tableId,
          isFav: !favTables.includes(table.tableId),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.data && response.data.status === "RS_OK") {
        // Dispatch to Redux store
        const _fTables = response.data.favTables;
        dispatch(favTablesLoaded(_fTables));
      } else {
        throw new Error(response.data.message);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <div
      className={`relative flex z-10 w-[48%] justify-end mt-2 mb-2 sm:w-[22.5%] sm:ml-[18px] ${
        deviceType === "desktop" && "hover:scale-[1.075]"
      }`}
      key={table.tableId + "_game"}
      style={{
        height: `${divSize.width * (deviceType === "desktop" ? 0.48 : 0.28)}px`,
      }}
    >
      <div
        className="thumbnail w-full h-full bg-cover bg-no-repeat bg-center cursor-pointer shadow-md my-auto rounded-xl"
        style={{
          animation: `slide_${table.slug} 2s infinite steps(50)`,
        }}
        key={table.tableId + "_game_bg"}
        onClick={() => onGameClick(table.slug)}
      />
      <img
        className={`cursor-pointer p-1 absolute ${
          deviceType === "desktop"
            ? `w-[40px] ${
                TableDataMap[table.slug as keyof typeof TableDataMap]
                  .favIconPositionWeb
              } mr-[2.8%]`
            : `w-[32px] ${
                TableDataMap[table.slug as keyof typeof TableDataMap]
                  .favIconPositionMob
              } mr-[2%]`
        }`}
        src={favTables.includes(table.tableId) ? favActiveIcon : favIcon}
        alt="favorite"
        onClick={handleFavClick}
      />
    </div>
  );
};

export default GameTab;
