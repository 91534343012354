import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import soundIcon from "../..//assets/icons/sound.png";
import musicIcon from "../../assets/icons/music.png";
import SliderInput from "./sliderInput/SliderInput";

const AudioSettings: React.FC = () => {
  const deviceType = localStorage.getItem("deviceType");
  const username = sessionStorage.getItem("username");
  const [musicVolume, setMusicVolume] = useState(0);
  const [soundVolume, setSoundVolume] = useState(0);
  const [showControls, setShowControls] = useState<boolean>(false);

  useEffect(() => {
    setShowControls(false);
    // set music colume from cookies
    const musicVol = Cookies.get(`${username}_music`);
    if (musicVol) {
      setMusicVolume(parseFloat(musicVol));
    }

    // set sound colume from cookies
    const soundVol = Cookies.get(`${username}_sound`);
    if (soundVol) {
      setSoundVolume(parseFloat(soundVol));
    }
    setShowControls(true);
  }, [username]);

  const handleMusicVolumeUpdate = (val: number) => {
    setMusicVolume(val);
    Cookies.set(`${username}_music`, val.toFixed(2), { expires: 365 });
  };

  const handleSoundVolumeUpdate = (val: number) => {
    setSoundVolume(val);
    Cookies.set(`${username}_sound`, val.toFixed(2), { expires: 365 });
  };

  return (
    <>
      {showControls && (
        <div className="flex flex-col w-full">
          <div className="flex w-full justify-start items-center">
            <img
              src={musicIcon}
              alt="Music"
              className={
                deviceType === "desktop"
                  ? "w-[126px] h-[126px]"
                  : "w-[90px] h-[90px]"
              }
            />
            <div
              className={`gradient-text mr-8 ${
                deviceType === "desktop"
                  ? "text-[32px] font-bold"
                  : "text-[16px] font-semibold"
              }`}
            >
              MUSIC
            </div>
            <SliderInput
              id="music"
              min={0}
              max={1}
              step={0.01}
              initialValue={musicVolume}
              onChange={(val: number) => handleMusicVolumeUpdate(val)}
            ></SliderInput>
          </div>
          <div className="flex w-full justify-start items-center">
            <img
              src={soundIcon}
              alt="Sound"
              className={
                deviceType === "desktop"
                  ? "w-[126px] h-[126px]"
                  : "w-[90px] h-[90px]"
              }
            />
            <div
              className={`gradient-text mr-8 ${
                deviceType === "desktop"
                  ? "text-[32px] font-bold"
                  : "text-[16px] font-semibold"
              }`}
            >
              SOUND
            </div>
            <SliderInput
              id="sound"
              min={0}
              max={1}
              step={0.01}
              initialValue={soundVolume}
              onChange={(val: number) => handleSoundVolumeUpdate(val)}
            ></SliderInput>
          </div>
        </div>
      )}
    </>
  );
};

export default AudioSettings;
