import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../utils/buttons/Button";
import Modal from "../utils/modal/Modal";

interface ChangePasswordProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ isOpen, onClose }) => {
  const deviceType = localStorage.getItem("deviceType");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [spinnerText, setSpinnerText] = useState<string>("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError("");
    setSpinnerText("Updating Password...");
    setShowSpinner(true);
    try {
      const response = await axios.post(
        "https://backend.inferixai.link/api/updatepassword",
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.data) {
        setSpinnerText("Password changed successfully..!!");
        setTimeout(() => {
          setShowSpinner(false);
          navigate("/login");
        }, 1500);
      }
    } catch (err: any) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("Failed to Change Password");
      }
      setShowSpinner(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Change Password"
      titleStyleClasses={
        deviceType === "mobile"
          ? "text-[12px] -mt-[24px] -ml-[6px]"
          : "text-[20px] -ml-2"
      }
      showSpinner={showSpinner}
      spinnerText={spinnerText}
    >
      <div
        className={`flex flex-col w-3/4 justify-center px-4 mx-auto -mt-12 ${
          deviceType === "mobile" && "pt-20 max-h-[220px] overflow-y-auto"
        }`}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mt-4 mb-2">
            <input
              className="mt-1 bg-transparent text-white"
              type="password"
              id="current-password"
              value={currentPassword}
              placeholder="Current Password"
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex flex-col mt-4 mb-2">
            <input
              className="mt-1 bg-transparent text-white"
              type="password"
              id="new-password"
              value={newPassword}
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex flex-col mt-4 mb-2">
            <input
              className="mt-1 bg-transparent text-white"
              type="password"
              id="confirm-password"
              value={confirmPassword}
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex w-full justify-center mt-6">
            <Button classes="w-4/12" type="submit" text={"Save"}></Button>
          </div>
        </form>
        <div className="flex w-full p-2 -mt-2 text-red-600 text-sm font-medium justify-center">
          {error && <p>{error}</p>}
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
